import React, { useState, useEffect } from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import Layout from "../../../components/chat-channel/Layout";
import Container from "../../../components/chat-channel/Container";
import TitleAndMetaTags from "../../../components/common/TitleAndHeader";
import { RequestForm } from "../../../components/form";
import { GoToPopup } from "../../homepage";
import { CookiesPoup } from "../../homepage";
import { useMedia } from "use-media";
import { BlogCta } from "./helpdesk-problems-solutions";
import "react-accessible-accordion/dist/fancy-example.css";

import "react-alice-carousel/lib/alice-carousel.css";
import "react-accessible-accordion/dist/fancy-example.css";
import { CaseStudyCtaGoTo } from "./generative-ai-itsm";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

const topImage = require("../../../assets/images/market_ui/slack/blog_image.png");
const header_image = require("../../../assets/img/blogs/moveworks_al_img_header.png");
const header_image_mobile = require("../../../assets/img/blogs/moveworks_al_img_header_mob.png");
const section_1 = require("../../../assets/img/blogs/moveworks_al_img_1.png");
const section_2 = require("../../../assets/img/blogs/moveworks_al_img_2.png");
const section_3 = require("../../../assets/img/blogs/moveworks_al_img_3.png");
const section_4 = require("../../../assets/img/blogs/moveworks_al_img_4.png");
const section_5 = require("../../../assets/img/blogs/moveworks_al_img_5.png");
const section_6 = require("../../../assets/img/blogs/moveworks_al_img_6.png");
const section_7 = require("../../../assets/img/blogs/moveworks_al_img_7.png");
const section_8 = require("../../../assets/img/blogs/moveworks_al_img_8.png");
const section_9 = require("../../../assets/img/blogs/moveworks_al_img_9.png");
const section_10 = require("../../../assets/img/blogs/moveworks_al_img_10.png");
const section_11 = require("../../../assets/img/blogs/moveworks_al_img_11.png");
const section_12 = require("../../../assets/img/blogs/moveworks_al_img_12.png");

const right_image = require("../../../assets/img/blogs/moveworks_al_img_right.png");

const icon_one = require("../../../assets/img/blogs/mv_al_icon_1.png");
const icon_two = require("../../../assets/img/blogs/mv_al_icon_2.png");
const icon_three = require("../../../assets/img/blogs/mv_al_icon_3.png");
const icon_four = require("../../../assets/img/blogs/mv_al_icon_4.png");
const icon_five = require("../../../assets/img/blogs/mv_al_icon_5.png");

const cta_1 = require("../../../assets/img/blogs/tsvg_cta_1.png");
const cta_2 = require("../../../assets/img/blogs/tsvg_cta_2.png");
const cta_1_mob = require("../../../assets/img/blogs/tsvg_cta_1_mob.png");
const cta_2_mob = require("../../../assets/img/blogs/tsvg_cta_2_mob.png");
const arrow_open = require("../../../assets/images/testimonials/arrow_open_blog.svg");
const arrow_close = require("../../../assets/images/testimonials/arrow_close_blog.svg");
const author = require("../../../assets/img/blogs/deepa_2.png");
const author_mobile = require("../../../assets/img/blogs/mobile_deepa_2.png");

const ldSchema = {
  "@context": "https://schema.org",
  "@type": "BlogPosting",
  mainEntityOfPage: {
    "@type": "WebPage",
    "@id":
      "https://workativ.com/conversational-ai-platform/slack-chatbot-guide",
  },
  headline: "Slack Chatbot Guide for your Business",
  image: "",
  author: {
    "@type": "Organization",
    name: "Team Workativ",
  },
  publisher: {
    "@type": "Organization",
    name: "Workativ",
    logo: {
      "@type": "ImageObject",
      url:
        "https://workativ.com/static/workativ-logo-5eea21f2dcd20bbbbf52debb41c0f26e.png",
    },
  },
  datePublished: "2021-08-05",
  dateModified: "2021-08-05",
};

export default function FeatureHomePage() {
  const isMobile = useMedia({ maxWidth: "520px" });
  const isSmall = useMedia({ maxWidth: "520px" });
  const backgroundImage = isSmall ? header_image_mobile : header_image;
  return (
    <>
      <TitleAndMetaTags
        title="Best Moveworks Alternatives for IT Support Automation"
        description="Discover the best Moveworks alternatives to support service desk automation within MS Teams and Slack. Workativ makes a better choice as GenAI-based chatbot support."
        keywords={[
          "ai for itsm, ai itsm, itsm, itsm ai, ai in itsm, ai service management, artificial intelligence service management",
        ]}
        ogImage={header_image}
        schema={ldSchema}
      />

      <Container>
        <Layout backgroundColor={"roi_bg"} logoFor="ASSISTANT">
          <section
            className={`w-100 float-left blog_top_header_upt striking_blog_header_upt ${
              isSmall ? "align-items-start" : null
            }`}
            style={{
              backgroundImage: `url(${backgroundImage})`,
            }}
          >
            <div className="container">
              <div
                className={`flex_cont_top_blog_img  ${
                  isMobile ? "text-align-center" : "text-align-left"
                }`}
              >
                {" "}
                {isMobile ? (
                  <h1 className="font-page-header-home-blog color-black">
                    Best Moveworks Alternatives for IT Support Automation
                  </h1>
                ) : (
                  <h1 className="font-page-header-home-blog-new color-black">
                    Best Moveworks
                    <br /> Alternatives for IT
                    <br /> Support Automation
                  </h1>
                )}
              </div>
            </div>
          </section>
          {isMobile ? <ContentContainerMobile /> : <ContentContainer />}
          {isMobile ? <RelatedBlogs /> : null}
          <RequestForm isFooterForm={true} />
        </Layout>
      </Container>
    </>
  );
}
function ContentContainer() {
  const [activeLink, setActiveLink] = useState("");
  const handleClick = (section) => {
    setActiveLink(section);
  };

  return (
    <section className="w-100 float-left margin-bottom-40 blog_content_parent_container">
      <div className="container">
        <div className="grid_container_goto_second grid_container_blogs_content">
          <div className="grid-left-container-goto blogs_grid_left_container w-100">
            <div className="sticky_container_goto_case">
              <h2 className="font-section-sub-header-small text-decoration-none">
                Table of Contents
              </h2>
              <hr className="underline_blogs_toc mt-0 mb-4"></hr>
              <div className="redirection-flex-container-blog">
                <AnchorLink
                  offset={180}
                  href="#section1"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section1"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section1")}
                >
                  1. Why consider Moveworks alternatives? 
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section2"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section2"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section2")}
                >
                  2. What to look for in Moveworks platform alternatives?
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section3"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section3"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section3")}
                >
                  3. Best Moveworks Alternatives for 2025
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section4"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section4"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section4")}
                >
                  4. Workativ Assistant: finding you a better choice for
                  Moveworks alternatives
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section5"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section5"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section5")}
                >
                  5. FAQs
                </AnchorLink>
              </div>
            </div>
          </div>
          <div className="grid-right-container-goto">
            <div className="divs_blogs_upt d-flex gap-20">
              <div className="moveworks-left-side-content">
                <h2 className="font-section-sub-header-blog color-alternative-blue">
                  Introduction 
                </h2>
                <p className="font-section-normal-text-testimonials line-height-18">
                  Are you looking for Moveworks alternatives yet want to
                  facilitate{" "}
                  <a href="https://workativ.com/conversational-ai-platform/blog/ai-it-service-management">
                    AI-driven ITSM?
                  </a>{" "}
                  The fact is Moveworks is not the only agentic AI copilot for
                  ITSM that can help you transition to AI-driven service desks.
                  Moveworks is indeed a better choice, and it makes good sense
                  for IT leaders to make things easy for employee productivity
                  and business growth. However, every IT leader needs to know
                  some hidden truths about Moveworks’ limitations.  
                </p>

                <p className="font-section-normal-text-testimonials line-height-18">
                  As part of the enterprise objective to turn service desks into
                  a superpower of knowledge engine and ITSM copilot, Moveworks
                  utilizes GPT and agentic AI architecture to enhance employee
                  service automation for maximum ROI. Surprisingly, Moveworks
                  alternatives and competitors can offer more specific features
                  and benefits than you could imagine for your needs. 
                </p>
                <p className="font-section-normal-text-testimonials line-height-18">
                  Let’s find out how Moveworks platform competitors and
                  alternatives can fulfill your{" "}
                  <a href="https://workativ.com/conversational-ai-platform/blog/generative-ai-itsm">
                    AI-driven ITSM
                  </a>{" "}
                  objectives, allowing you to make a good investment choice and
                  transform your service desks.
                </p>
              </div>
              <div className="moveworks-right-side-image">
                <img src={right_image} />
              </div>
            </div>
            <div className="divs_blogs_upt float-left" id="section1">
              <h2 className="font-section-sub-header-blog color-alternative-blue">
                Why consider Moveworks alternatives? 
              </h2>
              <p className="font-section-normal-text-testimonials line-height-18">
                Moveworks provides an agentic AI experience for your employees
                directly in Microsoft Teams and Slack for self-service by
                eliminating L1 support, offering seamless end-to-end ITSM
                integration, and automated routing. However, the conversational
                AI platform Moveworks can be deceptive in many ways.
              </p>
              <h3 className="font-section-sub-header-small color-alternative-blue">
                Limitation of customizability 
              </h3>
              <img src={section_1} className="blog_image_top_bt" alt="" />
              <p className="font-section-normal-text-testimonials line-height-18">
                Moveworks seems less flexible in offering customizability. As
                some reviews on G2 suggest, implementing the best use cases or
                workflows requires a lot of fine-tuning. Also, Implementing and
                integrating Moveworks with ITSM tools can be heavy lifting and
                time-consuming, overwhelming for non-technical individuals.
                Hence, even if it promises to offer an easy-to-use creator
                studio, a steep learning curve is essential, incurring developer
                costs.  
              </p>
              <h3 className="font-section-sub-header-small color-alternative-blue">
                Longer deployment time 
              </h3>
              <img src={section_2} className="blog_image_top_bt" alt="" />
              <img src={section_3} className="blog_image_top_bt" alt="" />
              <p className="font-section-normal-text-testimonials line-height-18">
                Many experienced IT service desk consultants would recommend
                Moveworks alternatives due to the longer deployment time. Many
                users also see it as a la carte. Moveworks has a tools-based
                approach so each new offering might need separate configurations
                through integration and customization. This would ultimately
                lead to higher developer costs and longer deployment times. 
              </p>
              <h3 className="font-section-sub-header-small color-alternative-blue">
                Leakage of sensitive data
              </h3>
              <img src={section_4} className="blog_image_top_bt" alt="" />
              <p className="font-section-normal-text-testimonials line-height-18">
                Access controls protect business data from unauthorized or
                unintentional use by specific users. Moveworks has various
                configurations to implement access control for multiple
                purposes, such as Moveworks Managed Permissions and Platform
                Permissions Integration. Since implementation can be tricky, it
                is difficult for someone to control the access level. As a
                result, sensitive data can be easily fetched using closely
                related matches.
              </p>
              <h3 className="font-section-sub-header-small color-alternative-blue">
                Integration challenges
              </h3>
              <img src={section_5} className="blog_image_top_bt" alt="" />
              <p className="font-section-normal-text-testimonials line-height-18">
                Every time you want to add a new feature or offering for
                workflow automation for service desks, you implement a
                workaround. However, the Moveworks platform has some complicated
                processes for integrating existing systems. This time-consuming
                process can take longer than expected for a particular feature
                or workflow. 
              </p>
              <h3 className="font-section-sub-header-small color-alternative-blue">
                No free trial 
              </h3>
              <img src={section_6} className="blog_image_top_bt" alt="" />
              <h3 className="font-section-sub-header-small color-alternative-blue">
                Lack of agent support 
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                Agent tools are a significant feature that empowers agents and
                unleashes the best potential for help desk automation. To reduce
                the count of service desk ticket volumes, Moveworks alternatives
                are an excellent choice for IT leaders as agent assist is
                available as an embedded offering, which Moveworks dramatically
                misses. 
              </p>
              <h3 className="font-section-sub-header-small color-alternative-blue">
                Hefty price tag
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                Moveworks primarily serves enterprise-level customers. Their
                offerings are usually customized, meaning they come at a hefty
                price tag. As per{" "}
                <a href="https://www.vendr.com/buyer-guides/moveworks">
                  Vendr's internal transaction data
                </a>{" "}
                for Moveworks, the maximum price can reach $780,000, and the
                average annual cost is $209,367.89. Also, many{" "}
                <a href="https://www.reddit.com/r/helpdesk/comments/sutlq3/moveworkschatbot_alternatives/">
                  Reddit
                </a>{" "}
                users couldn’t justify the costs. 
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Moveworks competitors seem to have better offerings, which might
                come as a surprise for employee service automation.
                Additionally, when looking for value for money and platform
                efficiency, alternatives to Moveworks make sense. 
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section2">
              <h2 className="font-section-sub-header-blog color-alternative-blue">
                What to look for in Moveworks platform alternatives?
              </h2>
              <img src={section_7} className="blog_image_top_bt" alt="" />
              <div className="d-flex gap-20">
                <img src={icon_one} className="mv-al-icon" />
                <p className="font-section-normal-text-testimonials line-height-18">
                  <span className="font-section-normal-text-testimonials-medium color-black">
                    Customization flexibility:
                  </span>{" "}
                  The platform you choose for service desk automation must offer
                  seamless customization to set up workflows. Look for
                  plug-and-play, no-code architecture, which is easy to use and
                  fast to implement workflows without a steep learning curve. 
                </p>
              </div>
              <div className="d-flex gap-20">
                <img src={icon_two} className="mv-al-icon" />
                <p className="font-section-normal-text-testimonials line-height-18">
                  <span className="font-section-normal-text-testimonials-medium color-black">
                    Integrations:
                  </span>{" "}
                  Ensure you can leverage the flexibility of{" "}
                  <a href="https://workativ.com/conversational-ai-platform/integrations">
                    seamless integrations for IT service management tools
                  </a>{" "}
                  to connect with conversational AI and unleash employee service
                  automation to the maximum degree. 
                </p>
              </div>
              <div className="d-flex gap-20">
                <img src={icon_three} className="mv-al-icon" />
                <p className="font-section-normal-text-testimonials line-height-18">
                  <span className="font-section-normal-text-testimonials-medium color-black">
                    Free chatbot builder:
                  </span>{" "}
                  Confirm that you can access{" "}
                  <a href="https://workativ.com/conversational-ai-platform/chatbot-builder">
                    free chatbot builder
                  </a>{" "}
                  or free trials to determine the level of adaptiveness and user
                  experience for your specific business needs. 
                </p>
              </div>
              <div className="d-flex gap-20">
                <img src={icon_four} className="mv-al-icon" />
                <p className="font-section-normal-text-testimonials line-height-18">
                  <span className="font-section-normal-text-testimonials-medium color-black">
                    {" "}
                    NLP engine:
                  </span>{" "}
                  It is critical to check that your conversational AI or service
                  desk automation platform best uses the{" "}
                  <a href="https://workativ.com/conversational-ai-platform/blog/conversational-ai-focus-on-user-experience">
                    NLP engine
                  </a>{" "}
                  to determine user intent and context by reducing ambiguity. A
                  robust NLP engine can also better use the knowledge base to
                  provide customized answers and prevent fallback scenarios. 
                </p>
              </div>
              <div className="d-flex gap-20">
                <img src={icon_five} className="mv-al-icon" />
                <p className="font-section-normal-text-testimonials line-height-18">
                  <span className="font-section-normal-text-testimonials-medium color-black">
                    Security:
                  </span>{" "}
                  AI can be whimsical. Look for what Moveworks competitors have
                  for security controls to prevent hallucinations and ensure PII
                  security. End-to-end encryptions, access controls, and
                  compliance with the regulatory bodies are essential, though. 
                </p>
              </div>
            </div>
            <div className="divs_blogs_upt float-left" id="section3">
              <h2 className="font-section-sub-header-blog color-alternative-blue">
                Best Moveworks Alternatives for 2025
              </h2>
              <h3 className="font-section-sub-header-small color-black">
                Workativ Assistant
              </h3>
              <img src={section_8} className="blog_image_top_bt" alt="" />
              <p className="font-section-normal-text-testimonials line-height-18">
                <a href="https://workativ.com/conversational-ai-platform">
                  Workativ Assistant
                </a>{" "}
                is a ChatGPT-like AI copilot designed to transform how employees
                perform their everyday tasks. Leveraging the power of Generative
                AI and LLMs, Workativ Assistant helps modernize the service desk
                by improving auto-resolution capabilities through GenAI-powered
                self-service automation. Workativ unlocks the immense potential
                of Generative AI by combining hybrid NLU or RAG-based systems to
                enhance the veracity of generated responses and help resolve 80%
                of repetitive IT support issues through automation. Workativ’s
                no-code visual chatbot canvas is easy to use to build your IT
                and HR support chatbots with workflow automation and enhances
                faster time to market. 
              </p>
              <h3 className="font-section-sub-header-small color-black">
                Features 
              </h3>
              <ul className="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  No-code chatbot builder built on GenAI architecture
                </li>
                <li className="font-section-normal-text-testimonials">
                  Four-in-one IT & HR support automation platform to centralize
                  data and enhance support with conversational AI, Knowledge AI,
                  app workflow automation, and shared live inbox
                </li>
                <li className="font-section-normal-text-testimonials">
                  GenAI-powered ITSM for streamlined service desks
                </li>
                <li className="font-section-normal-text-testimonials">
                  GenAI-powered chatbot for the help desk
                </li>
                <li className="font-section-normal-text-testimonials">
                  Single to multi-step workflows for cross-functional tasks
                </li>
                <li className="font-section-normal-text-testimonials">
                  Simple UI to deploy from simple to complicated workflows
                </li>
                <li className="font-section-normal-text-testimonials">
                  Ready to use FAQs builder
                </li>
                <li className="font-section-normal-text-testimonials">
                  Custom chatbots and predefined chatbots download from the
                  marketplace
                </li>
                <li className="font-section-normal-text-testimonials">
                  100+ integrations with leading industry applications for ITSM
                  management
                </li>
                <li className="font-section-normal-text-testimonials">
                  MS Teams, Slack, and web widget integration for omnichannel
                  support
                </li>
                <li className="font-section-normal-text-testimonials">
                  RAG-based systems to improve logic-driven dialogs for users
                </li>
                <li className="font-section-normal-text-testimonials">
                  Built-in live chat
                </li>
                <li className="font-section-normal-text-testimonials">
                  Smart notification alerts
                </li>
                <li className="font-section-normal-text-testimonials">
                  Personalized role-based access
                </li>
                <li className="font-section-normal-text-testimonials">
                  Analytics and insights to provide user interactions and areas
                  for improvement
                </li>
              </ul>
              <h3 className="font-section-sub-header-small color-black">
                Key benefits
              </h3>
              <ul className="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Faster time to market
                </li>
                <li className="font-section-normal-text-testimonials">
                  Enhanced user productivity
                </li>
                <li className="font-section-normal-text-testimonials">
                  Knowledge AI for knowledge management
                </li>
                <li className="font-section-normal-text-testimonials">
                  RAG approach or hybrid NLU for domain response generation
                </li>
                <li className="font-section-normal-text-testimonials">
                  Agent screen for improved productivity
                </li>
                <li className="font-section-normal-text-testimonials">
                  Integration with leading ITSM tools for service management
                  improvement
                </li>
                <li className="font-section-normal-text-testimonials">
                  ChatGPT-like flexibility for users
                </li>
                <li className="font-section-normal-text-testimonials">
                  Flexible onboarding
                </li>
                <li className="font-section-normal-text-testimonials">
                  Robust platform security
                </li>
                <li className="font-section-normal-text-testimonials">
                  Integrated customer support
                </li>
                <li className="font-section-normal-text-testimonials">
                  Free trials
                </li>
                <li className="font-section-normal-text-testimonials">
                  Cost-effective
                </li>
              </ul>
              <h3 className="font-section-sub-header-small color-black">
                Downside
              </h3>
              <ul className="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Help documents are not provided
                </li>
                <li className="font-section-normal-text-testimonials">
                  WhatsApp alerts missing
                </li>
              </ul>
              <h3 className="font-section-sub-header-small color-black">
                Pricing
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                Workativ offers several pricing plans. The Starter costs $1530,
                and the Pro costs $2250.{" "}
                <a href="https://workativ.com/contact-us">Contact sales</a> for
                Enterprise pricing.
              </p>
              <h3 className="font-section-sub-header-small color-black">
                REZOLVE.ai
              </h3>
              <img src={section_9} className="blog_image_top_bt" alt="" />
              <p className="font-section-normal-text-testimonials line-height-18">
                <a href="http://REZOLVE.ai">REZOLVE.ai</a> is a Generative
                AI-powered ITSM chatbot. It calls itself just another AI copilot
                for service desk queries for employees. Its GenAI-powered AITSM
                chatbot redefines employee experience by automating HR and IT
                support issues. REZOLVE.ai enables ITSM integration of its
                chatbots for MS Teams to streamline repetitive workflows using
                AI-powered knowledge management and conversational knowledge. It
                helps auto-deflect 25-35% of service desk tickets while
                improving agent productivity. 
              </p>
              <h3 className="font-section-sub-header-small color-black">
                Features
              </h3>
              <ul className="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Generative AI-driven ITSM chatbot
                </li>
                <li className="font-section-normal-text-testimonials">
                  MS Teams chatbot for self-service
                </li>
                <li className="font-section-normal-text-testimonials">
                  Seamless integrations with 150+ applications
                </li>
                <li className="font-section-normal-text-testimonials">
                  Desktop automation
                </li>
                <li className="font-section-normal-text-testimonials">
                  Scalability for IT support, HR support, facility support
                </li>
                <li className="font-section-normal-text-testimonials">
                  Multi-tenant capabilities
                </li>
                <li className="font-section-normal-text-testimonials">
                  No code automation studio for workflow design
                </li>
                <li className="font-section-normal-text-testimonials">
                  Chatbot analytics and dashboards
                </li>
                <li className="font-section-normal-text-testimonials">
                  Access approvals for MS Teams
                </li>
                <li className="font-section-normal-text-testimonials">
                  Personalized role-based access
                </li>
              </ul>

              <h3 className="font-section-sub-header-small color-black">
                Key Benefits
              </h3>
              <ul className="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Exceptional implementation and deployment support
                </li>
                <li className="font-section-normal-text-testimonials">
                  Easy-to-use user interface
                </li>
                <li className="font-section-normal-text-testimonials">
                  Comprehensive knowledge management
                </li>
                <li className="font-section-normal-text-testimonials">
                  Integrations with various business systems
                </li>
                <li className="font-section-normal-text-testimonials">
                  GDPR compliant
                </li>
              </ul>

              <h3 className="font-section-sub-header-small color-black">
                Downsides
              </h3>
              <ul className="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Not all features are available
                </li>
                <li className="font-section-normal-text-testimonials">
                  Complex admin settings
                </li>
              </ul>

              <h3 className="font-section-sub-header-small color-black">
                Pricing
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                Contact sales for custom pricing options.
              </p>
              <h3 className="font-section-sub-header-small color-black">
                AISERA
              </h3>
              <img src={section_10} className="blog_image_top_bt" alt="" />
              <p className="font-section-normal-text-testimonials line-height-18">
                <a href="https://aisera.com/products/ai-service-desk/">
                  AISERA AI Service Desk
                </a>{" "}
                is the enterprise co-pilot to reshape service desk automation
                with ChatGPT-like responses. Built on domain-specific LLMs,
                AISERA aims to automate 75% of employee requests, helping them
                achieve productivity and focus on strategic workflows. With
                AISERA’s agentic AI copilot, businesses can automate support
                with minimal human interventions for ESM operations such as IT,
                HR, Finance, Legal, and Facilities. Microsoft Teams is the
                chatbot deployment platform for businesses to automate
                responses, improve productivity, and boost efficiency.
              </p>
              <h3 className="font-section-sub-header-small color-black">
                Features
              </h3>
              <ul className="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Comprehensive AI service desks with ChatGPT-like AI copilot
                  interface
                </li>
                <li className="font-section-normal-text-testimonials">
                  Omnichannel employee support via web, chat, emails, mobile
                  apps
                </li>
                <li className="font-section-normal-text-testimonials">
                  LLM-built NLP engine for improved context and intent
                </li>
                <li className="font-section-normal-text-testimonials">
                  More than 1200 pre-trained workflows for automation
                </li>
                <li className="font-section-normal-text-testimonials">
                  Auto-triage for ticket management
                </li>
                <li className="font-section-normal-text-testimonials">
                  Slack and MS Teams-based self-service automation
                </li>
                <li className="font-section-normal-text-testimonials">
                  Task-specific AI agents or virtual agents for auto-resolutions
                </li>
                <li className="font-section-normal-text-testimonials">
                  Embedded agent assist
                </li>
                <li className="font-section-normal-text-testimonials">
                  Enterprise-wide search for improved knowledge management
                </li>
                <li className="font-section-normal-text-testimonials">
                  Live agent handover
                </li>
              </ul>

              <h3 className="font-section-sub-header-small color-black">
                Key Benefits
              </h3>
              <ul className="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Multifaceted support for ESM
                </li>
                <li className="font-section-normal-text-testimonials">
                  Proactive notifications for users
                </li>
                <li className="font-section-normal-text-testimonials">
                  Knowledgeable technical team for ease of deployment
                </li>
                <li className="font-section-normal-text-testimonials">
                  Customer and employee experience management
                </li>
                <li className="font-section-normal-text-testimonials">
                  Intuitive interface for wide adoption
                </li>
              </ul>

              <h3 className="font-section-sub-header-small color-black">
                Downsides
              </h3>
              <ul className="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Poor access to help documentation
                </li>
                <li className="font-section-normal-text-testimonials">
                  Outdated concierge articles
                </li>
              </ul>

              <h3 className="font-section-sub-header-small color-black">
                Pricing
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                Contact sales for custom pricing options.
              </p>
              <h3 className="font-section-sub-header-small color-black">
                kore.ai
              </h3>
              <img src={section_11} className="blog_image_top_bt" alt="" />
              <p className="font-section-normal-text-testimonials line-height-18">
                kore.ai’s XO Automation AI platform helps reimagine the contact
                center and help desk by automating information discovery for
                auto-resolutions. The XO platform provides an easy
                plug-and-play, no-code interface to design and deploy VA with
                LLM-powered capabilities. XO platform offers efficiency and
                productivity for customers, employees, and agents. 
              </p>

              <h3 className="font-section-sub-header-small color-black">
                Features
              </h3>
              <ul className="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  RAG-based capabilities for generative answers for employees
                  and customers
                </li>
                <li className="font-section-normal-text-testimonials">
                  Multimodal NLP engine for improved context and intent
                  understanding
                </li>
                <li className="font-section-normal-text-testimonials">
                  Generative AI-powered search capabilities for self-service
                </li>
                <li className="font-section-normal-text-testimonials">
                  Omni-channel customer support
                </li>
                <li className="font-section-normal-text-testimonials">
                  Multilingual abilities to improve adoption
                </li>
                <li className="font-section-normal-text-testimonials">
                  GenAI-powered human-like conversations for user experience
                </li>
                <li className="font-section-normal-text-testimonials">
                  Configurable alerts for employees and agents
                </li>
                <li className="font-section-normal-text-testimonials">
                  Prebuilt integrations with tools such as OpenAI, Anthropic,
                  and Azure
                </li>
                <li className="font-section-normal-text-testimonials">
                  Seamless integrations with industry-wide tools and
                  applications
                </li>
                <li className="font-section-normal-text-testimonials">
                  AI copilot to auto-generate dialogs
                </li>
              </ul>

              <h3 className="font-section-sub-header-small color-black">
                Key Benefits
              </h3>
              <ul className="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Ease of escalation of IT incident ticketing
                </li>
                <li className="font-section-normal-text-testimonials">
                  Integrated knowledge management
                </li>
                <li className="font-section-normal-text-testimonials">
                  User-friendly interface for implementation and deployment
                </li>
                <li className="font-section-normal-text-testimonials">
                  Guided learning for AI Automation Basic Training
                </li>
                <li className="font-section-normal-text-testimonials">
                  All-encompassing features for bot development
                </li>
              </ul>

              <h3 className="font-section-sub-header-small color-black">
                Downsides
              </h3>
              <ul className="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Slow bot builder
                </li>
                <li className="font-section-normal-text-testimonials">
                  Complicated customizability
                </li>
              </ul>

              <h3 className="font-section-sub-header-small color-black">
                Pricing
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                Contact for pricing.
              </p>

              <h3 className="font-section-sub-header-small color-black">
                SysAid
              </h3>
              <img src={section_12} className="blog_image_top_bt" alt="" />
              <p className="font-section-normal-text-testimonials line-height-18">
                SysAid is a next-gen ITSM chatbot with generative AI
                capabilities. SysAid copilot seamlessly integrates with MS Teams
                and automates user interactions to find answers, solve problems,
                and create tickets when answers are unavailable through
                self-service. SysAid enables users to implement and deploy it
                quickly and readily. 
              </p>
              <h3 className="font-section-sub-header-small color-black">
                Features
              </h3>
              <ul className="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  SysAid ITSM copilot for self-service automation
                </li>
                <li className="font-section-normal-text-testimonials">
                  Generative AI answers for exceptional response delivery
                </li>
                <li className="font-section-normal-text-testimonials">
                  Out-of-the-box ITIL-driven templates for faster onboarding
                </li>
                <li className="font-section-normal-text-testimonials">
                  Easy-to-use design-builder for workflow automation
                </li>
                <li className="font-section-normal-text-testimonials">
                  Out-of-the-box integrations with 50+ apps and systems
                </li>
                <li className="font-section-normal-text-testimonials">
                  Advanced customization cards to complement the service desk
                  environment
                </li>
                <li className="font-section-normal-text-testimonials">
                  SysAid BI analytics dashboards for service desks
                </li>
                <li className="font-section-normal-text-testimonials">
                  Omnichannel support via chat, email, self-service, and Teams
                </li>
                <li className="font-section-normal-text-testimonials">
                  AI admins in the loop for continuous improvement of chat
                  responses
                </li>
                <li className="font-section-normal-text-testimonials">
                  Drag-and-drop simple and customizable UI
                </li>
              </ul>

              <h3 className="font-section-sub-header-small color-black">
                Key Benefits
              </h3>
              <ul className="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Seamless ticket escalation for employees and IT staff
                </li>
                <li className="font-section-normal-text-testimonials">
                  Integrated knowledge management for self-service
                </li>
                <li className="font-section-normal-text-testimonials">
                  Rapid issue resolutions for employee queries
                </li>
                <li className="font-section-normal-text-testimonials">
                  Routing and escalation rules improve SLAs
                </li>
                <li className="font-section-normal-text-testimonials">
                  Rapid go-live for users
                </li>
              </ul>

              <h3 className="font-section-sub-header-small color-black">
                Downsides
              </h3>
              <ul className="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Reporting features need improvements
                </li>
                <li className="font-section-normal-text-testimonials">
                  BI reports are chargeable
                </li>
              </ul>

              <h3 className="font-section-sub-header-small color-black">
                Pricing
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                Contact sales
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section4">
              <h2 className="font-section-sub-header-blog color-alternative-blue">
                Workativ Assistant: finding you a better choice for Moveworks
                alternatives 
              </h2>
              <p className="font-section-normal-text-testimonials line-height-18">
                Workativ Assistant offers flexibility and convenience for users
                with its no-code plug-and-play chatbot builder, making
                deployment more straightforward and faster for your enterprise
                needs. It is also an excellent choice for SMBs' needs. Workativ
                requires no steep learning curve to implement and deploy
                workflows in communication channels such as MS Teams and Slack.
                It combines the best features to improve the employee experience
                by elevating self-service capabilities and agent performance.
              </p>
              <ul className="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  <a href="https://workativ.com/conversational-ai-platform/knowledge-ai">
                    {" "}
                    Knowledge AI&nbsp;
                  </a>
                  is your employees' ITSM copilot. It possesses ChatGPT-like
                  abilities and turns your knowledge bases into Generative AI
                  chat for user queries by harnessing LLMs and GenAI. By
                  boosting knowledge retrieval capabilities, Knowledge AI helps
                  deflect tickets to reduce the burden on your service desks.
                </li>
                <li className="font-section-normal-text-testimonials">
                  <a href="https://workativ.com/conversational-ai-platform/shared-inbox">
                    Shared Live Inbox
                  </a>{" "}
                  is designed to empower and enhance agents’ efficiency and
                  productivity by unifying user calls in a centralized agent
                  window, giving granular visibility into ticket status, and
                  speeding up resolutions with AI suggestions, AI summaries, and
                  knowledge retrieval.
                </li>
                <li className="font-section-normal-text-testimonials">
                  <a href="https://workativ.com/conversational-ai-platform/conversational-ai">
                    LLM-powered conversational AI
                  </a>{" "}
                  continuously provides an engaging conversation experience
                  using powerful NLP and LLM engines. The Workativ bot can pick
                  up inputs and generate relevant responses by leveraging intent
                  detection and entity extraction abilities to help complete
                  simple to complex tasks.
                </li>
              </ul>

              <p className="font-section-normal-text-testimonials line-height-18">
                If you want to implement custom workflows and enrich employee
                experience with ITSM and service desk management,{" "}
                <a href="https://calendly.com/workativ/workativ-assistant-demo-page?month=2024-10">
                  schedule a demo today.
                </a>
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section5">
              <h2 className="font-section-sub-header-blog color-alternative-blue">
                FAQs
              </h2>
              <Faq
                faqData={faqData}
                containerNo={true}
                backgroundNo={true}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
function ContentContainerMobile() {
  const [activeLink, setActiveLink] = useState("");
  const handleClick = (section) => {
    setActiveLink(section);
  };

  const [tableoFContentsDisplay, settableoFContentsDisplay] = React.useState(
    "hide"
  );
  const onArrowClick = () => {
    if (tableoFContentsDisplay === "show") {
      settableoFContentsDisplay("hide");
    } else {
      settableoFContentsDisplay("show");
    }
  };

  return (
    <section className="w-100 float-left mb-3 mt-4">
      <div className="container">
        <div className="grid_container_goto_second grid_blogs_container_mobile">
          <div className="grid-left-container-goto">
            <div
              className="mobile_accordian_title_goto_case"
              onClick={onArrowClick}
            >
              <h2 className="font-section-sub-header-small mb-3">
                Table of Contents
              </h2>
              {tableoFContentsDisplay === "show" ? (
                <img
                  className="arrow_goto_case_study_mobile mb-3"
                  src={arrow_close}
                ></img>
              ) : (
                <img
                  className="arrow_goto_case_study_mobile mb-3"
                  src={arrow_open}
                ></img>
              )}
            </div>
            {tableoFContentsDisplay === "show" ? (
              <div className="mobile_accordian_content_goto_case mt-4">
                <div className="redirection-flex-container">
                  <AnchorLink
                    offset={180}
                    href="#section1"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section1"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section1")}
                  >
                    1. Why consider Moveworks alternatives? 
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section2"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section2"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section2")}
                  >
                    2. What to look for in Moveworks platform alternatives?
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section3"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section3"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section3")}
                  >
                    3. Best Moveworks Alternatives for 2025
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section4"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section4"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section4")}
                  >
                    4. Workativ Assistant: finding you a better choice for
                    Moveworks alternatives
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section5"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section5"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section5")}
                  >
                    5. FAQs
                  </AnchorLink>
                </div>
              </div>
            ) : null}
          </div>
          <div className="grid-right-container-goto">
            <div className="divs_blogs_upt">
              <h2 className="font-section-sub-header-blog color-alternative-blue">
                Introduction 
              </h2>
              <p className="font-section-normal-text-testimonials line-height-18">
                Are you looking for Moveworks alternatives yet want to
                facilitate{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/ai-it-service-management">
                  AI-driven ITSM?
                </a>{" "}
                The fact is Moveworks is not the only agentic AI copilot for
                ITSM that can help you transition to AI-driven service desks.
                Moveworks is indeed a better choice, and it makes good sense for
                IT leaders to make things easy for employee productivity and
                business growth. However, every IT leader needs to know some
                hidden truths about Moveworks’ limitations.  
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                As part of the enterprise objective to turn service desks into a
                superpower of knowledge engine and ITSM copilot, Moveworks
                utilizes GPT and agentic AI architecture to enhance employee
                service automation for maximum ROI. Surprisingly, Moveworks
                alternatives and competitors can offer more specific features
                and benefits than you could imagine for your needs. 
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Let’s find out how Moveworks platform competitors and
                alternatives can fulfill your{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/generative-ai-itsm">
                  AI-driven ITSM
                </a>{" "}
                objectives, allowing you to make a good investment choice and
                transform your service desks.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section1">
              <h2 className="font-section-sub-header-blog color-alternative-blue">
                Why consider Moveworks alternatives? 
              </h2>
              <p className="font-section-normal-text-testimonials line-height-18">
                Moveworks provides an agentic AI experience for your employees
                directly in Microsoft Teams and Slack for self-service by
                eliminating L1 support, offering seamless end-to-end ITSM
                integration, and automated routing. However, the conversational
                AI platform Moveworks can be deceptive in many ways.
              </p>
              <h3 className="font-section-sub-header-small color-alternative-blue">
                Limitation of customizability 
              </h3>
              <img src={section_1} className="blog_image_top_bt" alt="" />
              <p className="font-section-normal-text-testimonials line-height-18">
                Moveworks seems less flexible in offering customizability. As
                some reviews on G2 suggest, implementing the best use cases or
                workflows requires a lot of fine-tuning. Also, Implementing and
                integrating Moveworks with ITSM tools can be heavy lifting and
                time-consuming, overwhelming for non-technical individuals.
                Hence, even if it promises to offer an easy-to-use creator
                studio, a steep learning curve is essential, incurring developer
                costs.  
              </p>
              <h3 className="font-section-sub-header-small color-alternative-blue">
                Longer deployment time 
              </h3>
              <img src={section_2} className="blog_image_top_bt" alt="" />
              <img src={section_3} className="blog_image_top_bt" alt="" />
              <p className="font-section-normal-text-testimonials line-height-18">
                Many experienced IT service desk consultants would recommend
                Moveworks alternatives due to the longer deployment time. Many
                users also see it as a la carte. Moveworks has a tools-based
                approach so each new offering might need separate configurations
                through integration and customization. This would ultimately
                lead to higher developer costs and longer deployment times. 
              </p>
              <h3 className="font-section-sub-header-small color-alternative-blue">
                Leakage of sensitive data
              </h3>
              <img src={section_4} className="blog_image_top_bt" alt="" />
              <p className="font-section-normal-text-testimonials line-height-18">
                Access controls protect business data from unauthorized or
                unintentional use by specific users. Moveworks has various
                configurations to implement access control for multiple
                purposes, such as Moveworks Managed Permissions and Platform
                Permissions Integration. Since implementation can be tricky, it
                is difficult for someone to control the access level. As a
                result, sensitive data can be easily fetched using closely
                related matches.
              </p>
              <h3 className="font-section-sub-header-small color-alternative-blue">
                Integration challenges
              </h3>
              <img src={section_5} className="blog_image_top_bt" alt="" />
              <p className="font-section-normal-text-testimonials line-height-18">
                Every time you want to add a new feature or offering for
                workflow automation for service desks, you implement a
                workaround. However, the Moveworks platform has some complicated
                processes for integrating existing systems. This time-consuming
                process can take longer than expected for a particular feature
                or workflow. 
              </p>
              <h3 className="font-section-sub-header-small color-alternative-blue">
                No free trial 
              </h3>
              <img src={section_6} className="blog_image_top_bt" alt="" />
              <h3 className="font-section-sub-header-small color-alternative-blue">
                Lack of agent support 
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                Agent tools are a significant feature that empowers agents and
                unleashes the best potential for help desk automation. To reduce
                the count of service desk ticket volumes, Moveworks alternatives
                are an excellent choice for IT leaders as agent assist is
                available as an embedded offering, which Moveworks dramatically
                misses. 
              </p>
              <h3 className="font-section-sub-header-small color-alternative-blue">
                Hefty price tag
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                Moveworks primarily serves enterprise-level customers. Their
                offerings are usually customized, meaning they come at a hefty
                price tag. As per{" "}
                <a href="https://www.vendr.com/buyer-guides/moveworks">
                  Vendr's internal transaction data
                </a>{" "}
                for Moveworks, the maximum price can reach $780,000, and the
                average annual cost is $209,367.89. Also, many{" "}
                <a href="https://www.reddit.com/r/helpdesk/comments/sutlq3/moveworkschatbot_alternatives/">
                  Reddit
                </a>{" "}
                users couldn’t justify the costs. 
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Moveworks competitors seem to have better offerings, which might
                come as a surprise for employee service automation.
                Additionally, when looking for value for money and platform
                efficiency, alternatives to Moveworks make sense. 
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section2">
              <h2 className="font-section-sub-header-blog color-alternative-blue">
                What to look for in Moveworks platform alternatives?
              </h2>
              <img src={section_7} className="blog_image_top_bt" alt="" />
              <div className="d-flex gap-20">
                <img src={icon_one} className="mv-al-icon" />
                <p className="font-section-normal-text-testimonials line-height-18">
                  <span className="font-section-normal-text-testimonials-medium color-black">
                    Customization flexibility:
                  </span>{" "}
                  The platform you choose for service desk automation must offer
                  seamless customization to set up workflows. Look for
                  plug-and-play, no-code architecture, which is easy to use and
                  fast to implement workflows without a steep learning curve. 
                </p>
              </div>
              <div className="d-flex gap-20">
                <img src={icon_two} className="mv-al-icon" />
                <p className="font-section-normal-text-testimonials line-height-18">
                  <span className="font-section-normal-text-testimonials-medium color-black">
                    Integrations:
                  </span>{" "}
                  Ensure you can leverage the flexibility of{" "}
                  <a href="https://workativ.com/conversational-ai-platform/integrations">
                    seamless integrations for IT service management tools
                  </a>{" "}
                  to connect with conversational AI and unleash employee service
                  automation to the maximum degree. 
                </p>
              </div>
              <div className="d-flex gap-20">
                <img src={icon_three} className="mv-al-icon" />
                <p className="font-section-normal-text-testimonials line-height-18">
                  <span className="font-section-normal-text-testimonials-medium color-black">
                    Free chatbot builder:
                  </span>{" "}
                  Confirm that you can access{" "}
                  <a href="https://workativ.com/conversational-ai-platform/chatbot-builder">
                    free chatbot builder
                  </a>{" "}
                  or free trials to determine the level of adaptiveness and user
                  experience for your specific business needs. 
                </p>
              </div>
              <div className="d-flex gap-20">
                <img src={icon_four} className="mv-al-icon" />
                <p className="font-section-normal-text-testimonials line-height-18">
                  <span className="font-section-normal-text-testimonials-medium color-black">
                    {" "}
                    NLP engine:
                  </span>{" "}
                  It is critical to check that your conversational AI or service
                  desk automation platform best uses the{" "}
                  <a href="https://workativ.com/conversational-ai-platform/blog/conversational-ai-focus-on-user-experience">
                    NLP engine
                  </a>{" "}
                  to determine user intent and context by reducing ambiguity. A
                  robust NLP engine can also better use the knowledge base to
                  provide customized answers and prevent fallback scenarios. 
                </p>
              </div>
              <div className="d-flex gap-20">
                <img src={icon_five} className="mv-al-icon" />
                <p className="font-section-normal-text-testimonials line-height-18">
                  <span className="font-section-normal-text-testimonials-medium color-black">
                    Security:
                  </span>{" "}
                  AI can be whimsical. Look for what Moveworks competitors have
                  for security controls to prevent hallucinations and ensure PII
                  security. End-to-end encryptions, access controls, and
                  compliance with the regulatory bodies are essential, though. 
                </p>
              </div>
            </div>
            <div className="divs_blogs_upt float-left" id="section3">
              <h2 className="font-section-sub-header-blog color-alternative-blue">
                Best Moveworks Alternatives for 2025
              </h2>
              <h3 className="font-section-sub-header-small color-black">
                Workativ Assistant
              </h3>
              <img src={section_8} className="blog_image_top_bt" alt="" />
              <p className="font-section-normal-text-testimonials line-height-18">
                <a href="https://workativ.com/conversational-ai-platform">
                  Workativ Assistant
                </a>{" "}
                is a ChatGPT-like AI copilot designed to transform how employees
                perform their everyday tasks. Leveraging the power of Generative
                AI and LLMs, Workativ Assistant helps modernize the service desk
                by improving auto-resolution capabilities through GenAI-powered
                self-service automation. Workativ unlocks the immense potential
                of Generative AI by combining hybrid NLU or RAG-based systems to
                enhance the veracity of generated responses and help resolve 80%
                of repetitive IT support issues through automation. Workativ’s
                no-code visual chatbot canvas is easy to use to build your IT
                and HR support chatbots with workflow automation and enhances
                faster time to market. 
              </p>
              <h3 className="font-section-sub-header-small color-black">
                Features 
              </h3>
              <ul className="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  No-code chatbot builder built on GenAI architecture
                </li>
                <li className="font-section-normal-text-testimonials">
                  Four-in-one IT & HR support automation platform to centralize
                  data and enhance support with conversational AI, Knowledge AI,
                  app workflow automation, and shared live inbox
                </li>
                <li className="font-section-normal-text-testimonials">
                  GenAI-powered ITSM for streamlined service desks
                </li>
                <li className="font-section-normal-text-testimonials">
                  GenAI-powered chatbot for the help desk
                </li>
                <li className="font-section-normal-text-testimonials">
                  Single to multi-step workflows for cross-functional tasks
                </li>
                <li className="font-section-normal-text-testimonials">
                  Simple UI to deploy from simple to complicated workflows
                </li>
                <li className="font-section-normal-text-testimonials">
                  Ready to use FAQs builder
                </li>
                <li className="font-section-normal-text-testimonials">
                  Custom chatbots and predefined chatbots download from the
                  marketplace
                </li>
                <li className="font-section-normal-text-testimonials">
                  100+ integrations with leading industry applications for ITSM
                  management
                </li>
                <li className="font-section-normal-text-testimonials">
                  MS Teams, Slack, and web widget integration for omnichannel
                  support
                </li>
                <li className="font-section-normal-text-testimonials">
                  RAG-based systems to improve logic-driven dialogs for users
                </li>
                <li className="font-section-normal-text-testimonials">
                  Built-in live chat
                </li>
                <li className="font-section-normal-text-testimonials">
                  Smart notification alerts
                </li>
                <li className="font-section-normal-text-testimonials">
                  Personalized role-based access
                </li>
                <li className="font-section-normal-text-testimonials">
                  Analytics and insights to provide user interactions and areas
                  for improvement
                </li>
              </ul>
              <h3 className="font-section-sub-header-small color-black">
                Key benefits
              </h3>
              <ul className="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Faster time to market
                </li>
                <li className="font-section-normal-text-testimonials">
                  Enhanced user productivity
                </li>
                <li className="font-section-normal-text-testimonials">
                  Knowledge AI for knowledge management
                </li>
                <li className="font-section-normal-text-testimonials">
                  RAG approach or hybrid NLU for domain response generation
                </li>
                <li className="font-section-normal-text-testimonials">
                  Agent screen for improved productivity
                </li>
                <li className="font-section-normal-text-testimonials">
                  Integration with leading ITSM tools for service management
                  improvement
                </li>
                <li className="font-section-normal-text-testimonials">
                  ChatGPT-like flexibility for users
                </li>
                <li className="font-section-normal-text-testimonials">
                  Flexible onboarding
                </li>
                <li className="font-section-normal-text-testimonials">
                  Robust platform security
                </li>
                <li className="font-section-normal-text-testimonials">
                  Integrated customer support
                </li>
                <li className="font-section-normal-text-testimonials">
                  Free trials
                </li>
                <li className="font-section-normal-text-testimonials">
                  Cost-effective
                </li>
              </ul>
              <h3 className="font-section-sub-header-small color-black">
                Downside
              </h3>
              <ul className="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Help documents are not provided
                </li>
                <li className="font-section-normal-text-testimonials">
                  WhatsApp alerts missing
                </li>
              </ul>
              <h3 className="font-section-sub-header-small color-black">
                Pricing
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                Workativ offers several pricing plans. The Starter costs $1530,
                and the Pro costs $2250.{" "}
                <a href="https://workativ.com/contact-us">Contact sales</a> for
                Enterprise pricing.
              </p>
              <h3 className="font-section-sub-header-small color-black">
                REZOLVE.ai
              </h3>
              <img src={section_9} className="blog_image_top_bt" alt="" />
              <p className="font-section-normal-text-testimonials line-height-18">
                <a href="http://REZOLVE.ai">REZOLVE.ai</a> is a Generative
                AI-powered ITSM chatbot. It calls itself just another AI copilot
                for service desk queries for employees. Its GenAI-powered AITSM
                chatbot redefines employee experience by automating HR and IT
                support issues. REZOLVE.ai enables ITSM integration of its
                chatbots for MS Teams to streamline repetitive workflows using
                AI-powered knowledge management and conversational knowledge. It
                helps auto-deflect 25-35% of service desk tickets while
                improving agent productivity. 
              </p>
              <h3 className="font-section-sub-header-small color-black">
                Features
              </h3>
              <ul className="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Generative AI-driven ITSM chatbot
                </li>
                <li className="font-section-normal-text-testimonials">
                  MS Teams chatbot for self-service
                </li>
                <li className="font-section-normal-text-testimonials">
                  Seamless integrations with 150+ applications
                </li>
                <li className="font-section-normal-text-testimonials">
                  Desktop automation
                </li>
                <li className="font-section-normal-text-testimonials">
                  Scalability for IT support, HR support, facility support
                </li>
                <li className="font-section-normal-text-testimonials">
                  Multi-tenant capabilities
                </li>
                <li className="font-section-normal-text-testimonials">
                  No code automation studio for workflow design
                </li>
                <li className="font-section-normal-text-testimonials">
                  Chatbot analytics and dashboards
                </li>
                <li className="font-section-normal-text-testimonials">
                  Access approvals for MS Teams
                </li>
                <li className="font-section-normal-text-testimonials">
                  Personalized role-based access
                </li>
              </ul>

              <h3 className="font-section-sub-header-small color-black">
                Key Benefits
              </h3>
              <ul className="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Exceptional implementation and deployment support
                </li>
                <li className="font-section-normal-text-testimonials">
                  Easy-to-use user interface
                </li>
                <li className="font-section-normal-text-testimonials">
                  Comprehensive knowledge management
                </li>
                <li className="font-section-normal-text-testimonials">
                  Integrations with various business systems
                </li>
                <li className="font-section-normal-text-testimonials">
                  GDPR compliant
                </li>
              </ul>

              <h3 className="font-section-sub-header-small color-black">
                Downsides
              </h3>
              <ul className="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Not all features are available
                </li>
                <li className="font-section-normal-text-testimonials">
                  Complex admin settings
                </li>
              </ul>

              <h3 className="font-section-sub-header-small color-black">
                Pricing
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                Contact sales for custom pricing options.
              </p>
              <h3 className="font-section-sub-header-small color-black">
                AISERA
              </h3>
              <img src={section_10} className="blog_image_top_bt" alt="" />
              <p className="font-section-normal-text-testimonials line-height-18">
                <a href="https://aisera.com/products/ai-service-desk/">
                  AISERA AI Service Desk
                </a>{" "}
                is the enterprise co-pilot to reshape service desk automation
                with ChatGPT-like responses. Built on domain-specific LLMs,
                AISERA aims to automate 75% of employee requests, helping them
                achieve productivity and focus on strategic workflows. With
                AISERA’s agentic AI copilot, businesses can automate support
                with minimal human interventions for ESM operations such as IT,
                HR, Finance, Legal, and Facilities. Microsoft Teams is the
                chatbot deployment platform for businesses to automate
                responses, improve productivity, and boost efficiency.
              </p>
              <h3 className="font-section-sub-header-small color-black">
                Features
              </h3>
              <ul className="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Comprehensive AI service desks with ChatGPT-like AI copilot
                  interface
                </li>
                <li className="font-section-normal-text-testimonials">
                  Omnichannel employee support via web, chat, emails, mobile
                  apps
                </li>
                <li className="font-section-normal-text-testimonials">
                  LLM-built NLP engine for improved context and intent
                </li>
                <li className="font-section-normal-text-testimonials">
                  More than 1200 pre-trained workflows for automation
                </li>
                <li className="font-section-normal-text-testimonials">
                  Auto-triage for ticket management
                </li>
                <li className="font-section-normal-text-testimonials">
                  Slack and MS Teams-based self-service automation
                </li>
                <li className="font-section-normal-text-testimonials">
                  Task-specific AI agents or virtual agents for auto-resolutions
                </li>
                <li className="font-section-normal-text-testimonials">
                  Embedded agent assist
                </li>
                <li className="font-section-normal-text-testimonials">
                  Enterprise-wide search for improved knowledge management
                </li>
                <li className="font-section-normal-text-testimonials">
                  Live agent handover
                </li>
              </ul>

              <h3 className="font-section-sub-header-small color-black">
                Key Benefits
              </h3>
              <ul className="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Multifaceted support for ESM
                </li>
                <li className="font-section-normal-text-testimonials">
                  Proactive notifications for users
                </li>
                <li className="font-section-normal-text-testimonials">
                  Knowledgeable technical team for ease of deployment
                </li>
                <li className="font-section-normal-text-testimonials">
                  Customer and employee experience management
                </li>
                <li className="font-section-normal-text-testimonials">
                  Intuitive interface for wide adoption
                </li>
              </ul>

              <h3 className="font-section-sub-header-small color-black">
                Downsides
              </h3>
              <ul className="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Poor access to help documentation
                </li>
                <li className="font-section-normal-text-testimonials">
                  Outdated concierge articles
                </li>
              </ul>

              <h3 className="font-section-sub-header-small color-black">
                Pricing
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                Contact sales for custom pricing options.
              </p>
              <h3 className="font-section-sub-header-small color-black">
                kore.ai
              </h3>
              <img src={section_11} className="blog_image_top_bt" alt="" />
              <p className="font-section-normal-text-testimonials line-height-18">
                kore.ai’s XO Automation AI platform helps reimagine the contact
                center and help desk by automating information discovery for
                auto-resolutions. The XO platform provides an easy
                plug-and-play, no-code interface to design and deploy VA with
                LLM-powered capabilities. XO platform offers efficiency and
                productivity for customers, employees, and agents. 
              </p>

              <h3 className="font-section-sub-header-small color-black">
                Features
              </h3>
              <ul className="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  RAG-based capabilities for generative answers for employees
                  and customers
                </li>
                <li className="font-section-normal-text-testimonials">
                  Multimodal NLP engine for improved context and intent
                  understanding
                </li>
                <li className="font-section-normal-text-testimonials">
                  Generative AI-powered search capabilities for self-service
                </li>
                <li className="font-section-normal-text-testimonials">
                  Omni-channel customer support
                </li>
                <li className="font-section-normal-text-testimonials">
                  Multilingual abilities to improve adoption
                </li>
                <li className="font-section-normal-text-testimonials">
                  GenAI-powered human-like conversations for user experience
                </li>
                <li className="font-section-normal-text-testimonials">
                  Configurable alerts for employees and agents
                </li>
                <li className="font-section-normal-text-testimonials">
                  Prebuilt integrations with tools such as OpenAI, Anthropic,
                  and Azure
                </li>
                <li className="font-section-normal-text-testimonials">
                  Seamless integrations with industry-wide tools and
                  applications
                </li>
                <li className="font-section-normal-text-testimonials">
                  AI copilot to auto-generate dialogs
                </li>
              </ul>

              <h3 className="font-section-sub-header-small color-black">
                Key Benefits
              </h3>
              <ul className="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Ease of escalation of IT incident ticketing
                </li>
                <li className="font-section-normal-text-testimonials">
                  Integrated knowledge management
                </li>
                <li className="font-section-normal-text-testimonials">
                  User-friendly interface for implementation and deployment
                </li>
                <li className="font-section-normal-text-testimonials">
                  Guided learning for AI Automation Basic Training
                </li>
                <li className="font-section-normal-text-testimonials">
                  All-encompassing features for bot development
                </li>
              </ul>

              <h3 className="font-section-sub-header-small color-black">
                Downsides
              </h3>
              <ul className="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Slow bot builder
                </li>
                <li className="font-section-normal-text-testimonials">
                  Complicated customizability
                </li>
              </ul>

              <h3 className="font-section-sub-header-small color-black">
                Pricing
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                Contact for pricing.
              </p>

              <h3 className="font-section-sub-header-small color-black">
                SysAid
              </h3>
              <img src={section_12} className="blog_image_top_bt" alt="" />
              <p className="font-section-normal-text-testimonials line-height-18">
                SysAid is a next-gen ITSM chatbot with generative AI
                capabilities. SysAid copilot seamlessly integrates with MS Teams
                and automates user interactions to find answers, solve problems,
                and create tickets when answers are unavailable through
                self-service. SysAid enables users to implement and deploy it
                quickly and readily. 
              </p>
              <h3 className="font-section-sub-header-small color-black">
                Features
              </h3>
              <ul className="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  SysAid ITSM copilot for self-service automation
                </li>
                <li className="font-section-normal-text-testimonials">
                  Generative AI answers for exceptional response delivery
                </li>
                <li className="font-section-normal-text-testimonials">
                  Out-of-the-box ITIL-driven templates for faster onboarding
                </li>
                <li className="font-section-normal-text-testimonials">
                  Easy-to-use design-builder for workflow automation
                </li>
                <li className="font-section-normal-text-testimonials">
                  Out-of-the-box integrations with 50+ apps and systems
                </li>
                <li className="font-section-normal-text-testimonials">
                  Advanced customization cards to complement the service desk
                  environment
                </li>
                <li className="font-section-normal-text-testimonials">
                  SysAid BI analytics dashboards for service desks
                </li>
                <li className="font-section-normal-text-testimonials">
                  Omnichannel support via chat, email, self-service, and Teams
                </li>
                <li className="font-section-normal-text-testimonials">
                  AI admins in the loop for continuous improvement of chat
                  responses
                </li>
                <li className="font-section-normal-text-testimonials">
                  Drag-and-drop simple and customizable UI
                </li>
              </ul>

              <h3 className="font-section-sub-header-small color-black">
                Key Benefits
              </h3>
              <ul className="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Seamless ticket escalation for employees and IT staff
                </li>
                <li className="font-section-normal-text-testimonials">
                  Integrated knowledge management for self-service
                </li>
                <li className="font-section-normal-text-testimonials">
                  Rapid issue resolutions for employee queries
                </li>
                <li className="font-section-normal-text-testimonials">
                  Routing and escalation rules improve SLAs
                </li>
                <li className="font-section-normal-text-testimonials">
                  Rapid go-live for users
                </li>
              </ul>

              <h3 className="font-section-sub-header-small color-black">
                Downsides
              </h3>
              <ul className="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Reporting features need improvements
                </li>
                <li className="font-section-normal-text-testimonials">
                  BI reports are chargeable
                </li>
              </ul>

              <h3 className="font-section-sub-header-small color-black">
                Pricing
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                Contact sales
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section4">
              <h2 className="font-section-sub-header-blog color-alternative-blue">
                Workativ Assistant: finding you a better choice for Moveworks
                alternatives 
              </h2>
              <p className="font-section-normal-text-testimonials line-height-18">
                Workativ Assistant offers flexibility and convenience for users
                with its no-code plug-and-play chatbot builder, making
                deployment more straightforward and faster for your enterprise
                needs. It is also an excellent choice for SMBs' needs. Workativ
                requires no steep learning curve to implement and deploy
                workflows in communication channels such as MS Teams and Slack.
                It combines the best features to improve the employee experience
                by elevating self-service capabilities and agent performance.
              </p>
              <ul className="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  <a href="https://workativ.com/conversational-ai-platform/knowledge-ai">
                    {" "}
                    Knowledge AI&nbsp;
                  </a>
                  is your employees' ITSM copilot. It possesses ChatGPT-like
                  abilities and turns your knowledge bases into Generative AI
                  chat for user queries by harnessing LLMs and GenAI. By
                  boosting knowledge retrieval capabilities, Knowledge AI helps
                  deflect tickets to reduce the burden on your service desks.
                </li>
                <li className="font-section-normal-text-testimonials">
                  <a href="https://workativ.com/conversational-ai-platform/shared-inbox">
                    Shared Live Inbox
                  </a>{" "}
                  is designed to empower and enhance agents’ efficiency and
                  productivity by unifying user calls in a centralized agent
                  window, giving granular visibility into ticket status, and
                  speeding up resolutions with AI suggestions, AI summaries, and
                  knowledge retrieval.
                </li>
                <li className="font-section-normal-text-testimonials">
                  <a href="https://workativ.com/conversational-ai-platform/conversational-ai">
                    LLM-powered conversational AI
                  </a>{" "}
                  continuously provides an engaging conversation experience
                  using powerful NLP and LLM engines. The Workativ bot can pick
                  up inputs and generate relevant responses by leveraging intent
                  detection and entity extraction abilities to help complete
                  simple to complex tasks.
                </li>
              </ul>

              <p className="font-section-normal-text-testimonials line-height-18">
                If you want to implement custom workflows and enrich employee
                experience with ITSM and service desk management,{" "}
                <a href="https://calendly.com/workativ/workativ-assistant-demo-page?month=2024-10">
                  schedule a demo today.
                </a>
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section5">
              <h2 className="font-section-sub-header-blog color-alternative-blue">
                FAQs
              </h2>
              <Faq
                faqData={faqData}
                containerNo={true}
                backgroundNo={true}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export const AuthorBio = () => {
  const isMobile = useMedia({ maxWidth: "520px" });
  return (
    <section className="w-100 float-left author_bio_parent_cont">
      <div className="author_bio_flex_container">
        {isMobile ? (
          <div className="author_bio_flex_left align-self-start">
            <img src={author_mobile} className="width-40"></img>

            <div className="align-self-center">
              <h2 className="font-section-sub-header-small-home mt-3">
                Deepa Majumder
              </h2>
              <h4 className="font-section-normal-text-testimonials">
                Content Writer
              </h4>{" "}
            </div>
          </div>
        ) : (
          <div className="author_bio_flex_left align-self-start">
            <img src={author}></img>
            <h2 className="font-section-sub-header-small-home mt-3">
              Deepa Majumder
            </h2>
            <h4 className="font-section-normal-text-testimonials">
              Content Writer
            </h4>{" "}
          </div>
        )}

        <div className="author_bio_flex_right w-100">
          <p className="font-section-normal-text-testimonials line-height-18">
            Deepa Majumder is a writer who nails the art of crafting bespoke
            thought leadership articles to help business leaders tap into rich
            insights in their journey of organization-wide digital
            transformation. Over the years, she has dedicatedly engaged herself
            in the process of continuous learning and development across
            business continuity management and organizational resilience.{" "}
          </p>
          <p className="font-section-normal-text-testimonials line-height-18 pb-0 mb-0">
            Her pieces intricately highlight the best ways to transform employee
            and customer experience. When not writing, she spends time on
            leisure activities.
          </p>
        </div>
      </div>
    </section>
  );
};
const RelatedBlogs = () => {
  const isMobile = useMedia({ maxWidth: "520px" });
  return (
    <div
      className={`most_popular_links ${isMobile ? "mt-4 container" : "mt-5"}`}
    >
      <div className="most_popular_header font-section-sub-header">
        Related Articles
      </div>
      <div className="most_popular_ul">
        <ul
          className="section__ul font-section-normal-text ul_related_blogs_upd"
          style={{ width: "100%", float: "left", borderBottom: "none" }}
        >
          <li>
            <a
              href="https://workativ.com/conversational-ai-platform/blog/top-itsm-trends-2023"
              className="font-section-normal-text-testimonials"
            >
              TOP ITSM Trends 2023: Preparing for the Future
            </a>
          </li>
          <li>
            <a
              href="https://workativ.com/conversational-ai-platform/blog/itsm-chatbot-guide"
              className="font-section-normal-text-testimonials"
            >
              ITSM CHATBOT- Use Cases and Benefits Explained
            </a>
          </li>
          <li>
            <a
              href="https://workativ.com/conversational-ai-platform/blog/service-desk-automation"
              className="font-section-normal-text-testimonials"
            >
              {" "}
              Service Desk Automation: ELEVATE YOUR IT SUPPORT EFFICIENCY
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};
export function Faq({ faqData, marginTrue, containerNo, backgroundNo }) {
  return (
    <React.Fragment>
      <div
        className={`mob_accordian mob_accordian_faq  p-40 ${
          marginTrue ? "mb-40" : null
        } ${backgroundNo ? "" : "background-grey"}`}
      >
        <section className="accordian_landing trial_accordian_landing">
          <div className={` ${containerNo ? "" : "container"}`}>
            <h2 className="font-section-sub-header">
              Frequently Asked Questions
            </h2>
            <div className="col-12 pl-0 faq_landing">
              <Accordion
                id="accordion"
                className="accordion pl-0"
                preExpanded={["a"]}
              >
                <div className="accordion-wrapper pricing_faq">
                  {faqData.map((data) => (
                    <AccordionItem uuid={data.uuid}>
                      <AccordionItemHeading>
                        <AccordionItemButton>{data.header}</AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p
                          dangerouslySetInnerHTML={{ __html: data.paragraph }}
                          className="font-section-normal-text-testimonials line-height-18"
                        />
                        {data.listItems ? (
                          <ul
                            className={`font-section-normal-text-testimonials  blogs_ul_upd list-accordian-blogs-know`}
                          >
                            {data.listItems.map((item, index) => (
                              <li
                                key={index}
                                className={`display-list-item ${
                                  data.listType ? data.listType : ""
                                }`}
                              >
                                {item}
                              </li>
                            ))}
                          </ul>
                        ) : null}
                        {data.paragraph1 && (
                          <p
                            className="para_2_pricing font-section-normal-text-testimonials line-height-18"
                            dangerouslySetInnerHTML={{
                              __html: data.paragraph1,
                            }}
                          />
                        )}
                        {data.headerWithMultiPara ? (
                          <div>
                            {data.subHeaderData.map((item, index) => (
                              <div
                                key={index}
                                className={`${
                                  index !== data.subHeaderData.length - 1
                                    ? "mb-4"
                                    : ""
                                }`}
                              >
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: item.subHeader,
                                  }}
                                  className="font-section-sub-header-small pb-0 mb-0 line-height-18"
                                />
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: item.para,
                                  }}
                                  className="font-section-normal-text-testimonials line-height-18"
                                />
                              </div>
                            ))}
                          </div>
                        ) : null}
                      </AccordionItemPanel>
                    </AccordionItem>
                  ))}
                </div>
              </Accordion>
            </div>
          </div>
        </section>
      </div>
    </React.Fragment>
  );
}
export const faqData = [
  {
    header:
      "Why do you need Moveworks alternatives for service desk operations?",
    paragraph:
      "From a pricing point of view, Moveworks seems quite costly for SMBs, including enterprise needs. Surprisingly, Moveworks alternatives offer more comprehensive features and solutions for service desk automation operations. ",
    active: "active",
    uuid: "a",
  },
  {
    header:
      "How do Moveworks alternatives and competitors offer better benefits for your service desk automation needs?",
    paragraph:
      "Moveworks developer documentation can be tricky, and implementing workflows requires a steep learning curve, even though it is a no-code platform. With alternatives to Moveworks platforms such as Workativ Assistant, businesses can achieve fast market time and drive value from the first day. ",
  },
  {
    header:
      "What should you consider when choosing an ITSM chatbot for MS Teams or Slack?",
    paragraph:
      "Several factors prove significant for ROI. Look for scalability, seamless integrations, customization, dedicated support, and cost-effectiveness. ",
  },
  {
    header:
      "How does a free chatbot builder make a difference in chatbot selection?",
    paragraph:
      "A free chatbot builder lets you explore your options in tandem with your business-specific needs. You can test and trial workflows for business use cases and discover their effectiveness for ITSM support. Workativ provides a free chatbot builder to help you make better decisions about your needs and gain ITSM benefits.",
  },
];
